// Content.js

import React from 'react';

const Content = () => {
  return (
    <div>
    <h2>Welcome to My Website</h2>
        <p>This is a sample content area.</p>

    </div>
  );
}

export default Content;
