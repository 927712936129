// Brick.js

import React from 'react';
import '../styles/breakout.css'; // Import CSS

const Brick = () => {
  return <div className="brick"></div>;
};

export default Brick;
