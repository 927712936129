// JsonBeautifier.js

import React from 'react';

const JsonBeautifier = () => {
  return (
    <div>
      <h2>JSON Beautifier</h2>
      <p>This is the JSON Beautifier page content.</p>
    </div>
  );
}

export default JsonBeautifier;
