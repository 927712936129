import React from 'react';

const SessionControls = () => {
  return (
    <div>
      <h2>Session Controls</h2>
      {/* Component for session control options (owner-only) */}
    </div>
  );
}

export default SessionControls;
