// About.js

import React from 'react';

const About = () => {
  return (
    <div>
      <h2>About</h2>
      <p>Weclome to my playground while I work on how to use React and using ChatGPT to do so.  Please checkout this website's
        <a href="https://github.com/matthewcook0485/tech-craft-kit"> GitHub </a>  </p>
    </div>
  );
}

export default About;
